import React from 'react';

export default function Sustainability() {
  return <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9517 14.4434C14.7651 12.825 14.2614 9.07594 11.8268 6.06963C9.39207 3.06332 5.94834 1.93819 4.13497 3.55658C2.32159 5.17497 2.82527 8.92404 5.25995 11.9303C7.69464 14.9367 11.1384 16.0618 12.9517 14.4434Z" stroke="#ff6326 " stroke-linejoin="bevel" />
    <path d="M14.5873 16.3535L4.41211 3.64661" stroke="#ff6326 " stroke-linejoin="bevel" />
    <path d="M11 12L11 5" stroke="#ff6326 " stroke-linejoin="bevel" />
  </svg>
    ;
}
