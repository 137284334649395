

import React from 'react';

export default function Intergrity() {
  return <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1V9.14608L3.75 11.5739L6.5 14L9.25 11.5739L12 9.14608V1H1Z" stroke="#ff6326 " stroke-linejoin="bevel" />
    <path d="M6.49935 3L7.27268 4.64707L9 4.91088L7.74967 6.19164L8.04471 8L6.49935 7.14707L4.95529 8L5.25033 6.19164L4 4.91088L5.72732 4.64707L6.49935 3Z" stroke="#ff6326 " stroke-linejoin="bevel" />
  </svg>;
}
