import React from 'react';

export default function Reliabilty() {
  return <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.964 8.1794C13.8042 9.7 13.1135 11.1156 12.0133 12.1773C10.9131 13.2391 9.47377 13.879 7.94844 13.9845C6.42312 14.0901 4.90941 13.6546 3.6734 12.7546C2.4374 11.8545 1.55823 10.5476 1.19046 9.06353C0.822683 7.57945 0.989849 6.01322 1.66256 4.6402C2.33528 3.26717 3.47049 2.17523 4.86861 1.55635C6.26673 0.937468 7.83827 0.831255 9.30695 1.25639C10.7756 1.68152 12.0474 2.61078 12.8988 3.8808" stroke="#ff6326 " stroke-linejoin="bevel" />
    <path d="M14 3L7.20304 9L5 7.11786" stroke="#ff6326 " stroke-linejoin="bevel" />
  </svg>

    ;
}
