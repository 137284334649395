import React from 'react'
import SmallSpacer from '../spacer/small-spacer'

export default function TitleBanner({children,title,className}) {
    return (
        <div className='flex al-i-c flex-c'>
            <h3 className='h3 max-500 text-center'>{title}</h3>
            <SmallSpacer/>
            <SmallSpacer/>
            <p className={'large-sentence max-800 text-center light-white ' + className}>{children}</p>
        </div>
    )
}
