import React from 'react'
import Excellence from '../../icons/about/excellence'
import Humility from '../../icons/about/humility'
import Intergrity from '../../icons/about/intergrity'
import Reliabilty from '../../icons/about/reliabilty'
import Sustainability from '../../icons/about/sustainability'
import Location from '../../icons/location'
import SmallSpacer from '../spacer/small-spacer'


function LocationSpacer() {
    return (
        <div className='flex flex-c flex-end'>
            <Location />
        </div>
    )
}
export default function Carousel() {
    return (
        <div className='flex ju-c'>
            <div className='about--carousel--wrapper--outer'>


                <SmallSpacer />
                <SmallSpacer />
                <div className='about--carousel--wrapper'>
                    <div className='flex about--carousel--wrapper--moving '>
                        <div className='about--carousel--item al-i-end'>
                            <Sustainability />
                            <h3 className='mr30 ml30'>sustainabilty</h3>
                            <Humility />
                            <h3 className='mr30 ml30'>humility</h3>
                            <Intergrity />
                            <h3 className='mr30 ml30'>integrity</h3>
                            <Reliabilty />
                            <h3 className='mr30 ml30'>reliability</h3>
                            <Excellence />
                            <h3 className='mr30 ml30'>excellence</h3>
                        </div>


                        <div className='about--carousel--item al-i-end'>

                            <Sustainability />
                            <h3 className='mr30 ml30'>sustainabilty</h3>
                            <Humility />
                            <h3 className='mr30 ml30'>humility</h3>
                            <Intergrity />
                            <h3 className='mr30 ml30'>integrity</h3>
                            <Reliabilty />
                            <h3 className='mr30 ml30'>reliability</h3>
                            <Excellence />
                            <h3 className='mr30 ml30'>excellence</h3>


                        </div>

                        <div className='about--carousel--item al-i-end'>

                            <Sustainability />
                            <h3 className='mr30 ml30'>sustainabilty</h3>
                            <Humility />
                            <h3 className='mr30 ml30'>humility</h3>
                            <Intergrity />
                            <h3 className='mr30 ml30'>integrity</h3>
                            <Reliabilty />
                            <h3 className='mr30 ml30'>reliability</h3>
                            <Excellence />
                            <h3 className='mr30 ml30'>excellence</h3>



                        </div>
                        <div className='about--carousel--item al-i-end'>

                            <Sustainability />
                            <h3 className='mr30 ml30'>sustainabilty</h3>
                            <Humility />
                            <h3 className='mr30 ml30'>humility</h3>
                            <Intergrity />
                            <h3 className='mr30 ml30'>integrity</h3>
                            <Reliabilty />
                            <h3 className='mr30 ml30'>reliability</h3>
                            <Excellence />
                            <h3 className='mr30 ml30'>excellence</h3>



                        </div>
                        <div className='about--carousel--item al-i-end'>

                            <Sustainability />
                            <h3 className='mr30 ml30'>sustainabilty</h3>
                            <Humility />
                            <h3 className='mr30 ml30'>humility</h3>
                            <Intergrity />
                            <h3 className='mr30 ml30'>integrity</h3>
                            <Reliabilty />
                            <h3 className='mr30 ml30'>reliability</h3>
                            <Excellence />
                            <h3 className='mr30 ml30'>excellence</h3>



                        </div>
                    </div>


                </div>
                <SmallSpacer />
                <SmallSpacer />
            </div>

        </div>


    )
}
