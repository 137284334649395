import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ScalingBanner from '../components/scaling-banner/scaling-banner'
import Spacer from '../components/spacer/spacer'
import OversetImage from '../components/overset-image/overset-image'
import TitleList from '../components/title/title-list'
import Carousel from '../components/about/carousel'
import SmallSpacer from '../components/spacer/small-spacer'
import BlockSlider from '../components/block-slider/block-slider'
import { SwiperSlide } from 'swiper/react'
import ContentContainer from '../components/content-container/content-container'
import { GatsbyImage } from 'gatsby-plugin-image'
import FadeInView from '../components/fade-inview/fade-inview'
import TitleBanner from '../components/title/title-banner'
import SquareScalingBanner from '../components/square-scaling-banner/square-scaling-banner'
import ProofPoint from '../components/proof-point/proof-point'
import { graphql } from 'gatsby'
import SubTitle from '../components/scaling-banner/sub-title'
function Svg() {
    return (
        <svg width="1188" height="350" viewBox="0 0 1188 350" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path d="M29.0065 334H74.1815L80.2049 287.534H126.24L132.264 334H177.439L134.415 32.8333H72.0303L29.0065 334ZM103.007 108.125L120.217 242.359H85.7979L103.007 108.125Z" fill="#EEEAD9" />
            <path d="M394.161 291.687C420.346 291.687 437.179 274.854 437.179 248.669V189.939C437.179 167.495 423.712 158.892 414.735 157.021C423.712 155.151 437.179 146.547 437.179 124.103V72.855C437.179 48.5404 418.475 29.8368 394.161 29.8368H332.439V291.687H394.161ZM371.716 137.195V69.1143H394.161C396.405 69.1143 397.901 70.2366 397.901 72.855V133.455C397.901 136.073 396.405 137.195 394.161 137.195H371.716ZM371.716 252.409V176.847H394.161C396.405 176.847 397.901 177.969 397.901 180.588V248.669C397.901 251.287 396.405 252.409 394.161 252.409H371.716Z" fill="#EEEAD9" />
            <path d="M659.735 255.26C681.978 255.26 696.278 240.96 696.278 218.717V66.1886C696.278 45.5337 680.389 29.6454 659.735 29.6454H643.846C623.191 29.6454 607.303 45.5337 607.303 66.1886V218.717C607.303 240.96 621.603 255.26 643.846 255.26H659.735ZM643.846 221.894C641.94 221.894 640.669 220.941 640.669 218.717V66.1886C640.669 63.9642 641.94 63.0109 643.846 63.0109H659.735C661.641 63.0109 662.912 63.9642 662.912 66.1886V218.717C662.912 220.941 661.641 221.894 659.735 221.894H643.846Z" fill="#EEEAD9" />
            <path d="M928.153 31.1622V188.143C928.153 190.006 927.089 190.804 925.492 190.804H912.189C910.593 190.804 909.528 190.006 909.528 188.143V31.1622H881.591V188.143C881.591 206.768 893.564 218.741 912.189 218.741H925.492C944.117 218.741 956.09 206.768 956.09 188.143V31.1622H928.153Z" fill="#EEEAD9" />
            <path d="M1097.94 30.8719V54.4411H1121.51V188H1145.08V54.4411H1168.65V30.8719H1097.94Z" fill="#EEEAD9" />
        </svg>

    )
}

export default function About({ data: { pageData: { timeline, proofPoints, heroImage, videoLink, timelineDescription, timelineTitle, theSmithfieldDifferenceImage, theSmithfieldDifferenceBackground, differnceDescription, subTitle, focusedOnCommunityImage,
    focusedOnCommunityParagraph,
    focusedOnCommunityTitle,
    focusedOnCommunityBackground,
    proudAsPunch,
    proudAsPunchPargraph,
}, operationsPage } }) {
    return (
        <Layout>
            <Seo title='About' />
            <ScalingBanner video={videoLink} image={heroImage} svg={<Svg />} title='Expertise' >
                <div className='flex flex-end'>
                    <SubTitle title='SMITHFIELD Cattle Company ' />
                </div>
            </ScalingBanner>
            <Spacer />
            <SmallSpacer />
            <ContentContainer>
                <TitleBanner title={timelineTitle}>
                    {timelineDescription}
                </TitleBanner>
            </ContentContainer>
            <Spacer />
            <FadeInView>
                <BlockSlider className='timeline--wrapper'>
                    {timeline.map(({ event, year, image }) => {
                        return (
                            <SwiperSlide className='max-400 pl30 pr30  ju-sb h100 timeline--grid'>
                                <h6 className='large-sentence text-left orange mb0'>{year}</h6>
                                <p className='body-copy'>{event}</p>
                                <div className='ratio-2-1 pos-rel w-100'>
                                    <GatsbyImage alt="" image={image.gatsbyImageData} className='r6 bg-image' />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </BlockSlider>
            </FadeInView>
            <Spacer />
            <OversetImage image={theSmithfieldDifferenceImage} background={theSmithfieldDifferenceBackground} />
            <Spacer />
            <ContentContainer>
                <h3 className='h3 max-350 text-center ma '>{subTitle}</h3>
            </ContentContainer>
            <Spacer />
            <TitleList heading={differnceDescription[0].heading} paragraphs={differnceDescription[0].paragraphs} />
            <Spacer />
            <FadeInView>
                <Carousel />
            </FadeInView>
            <SmallSpacer />
            <Spacer className='m-hide' />
            <OversetImage image={focusedOnCommunityImage} background={focusedOnCommunityBackground} />
            <Spacer />
            <FadeInView>
                <ContentContainer>
                    <TitleBanner className='max-700' title={focusedOnCommunityTitle}>
                        {focusedOnCommunityParagraph}
                    </TitleBanner>
                </ContentContainer>
            </FadeInView>
            <SmallSpacer className='m-hide' />
            <Spacer />
            <BlockSlider id='1' className='proof--slider' >
                {proofPoints.map((proofPoint) => {
                    return <SwiperSlide className='max-400'>
                        <ProofPoint proofPoint={proofPoint} />
                    </SwiperSlide>
                })}
            </BlockSlider>
            <SmallSpacer className='m-hide' />
            <Spacer />
            <ContentContainer>
                <FadeInView>
                    <div className='max-600 ma'>
                        <h6 className='small-serif mb20 '>{proudAsPunch}</h6>
                        <p className='body-copy'>{proudAsPunchPargraph}</p>

                    </div>
                </FadeInView>
            </ContentContainer>
            <Spacer />
            <SmallSpacer />
            <SquareScalingBanner image={operationsPage.heroImage} link='/operations'>
                <h3 className='small-serif'>Next Page</h3>
                <SmallSpacer />
                <h2 className='h1 m-h3'>Operations</h2>
            </SquareScalingBanner>
            <Spacer />
        </Layout>
    )
}


export const query = graphql`
query About{
    operationsPage:datoCmsOperationPage {
        heroImage {
            gatsbyImageData
        }
    }
    pageData:datoCmsAbout{
        heroImage {
            gatsbyImageData
            url
        }
        videoLink
        timelineDescription
        timelineTitle
        theSmithfieldDifferenceImage {
          gatsbyImageData
        }
        theSmithfieldDifferenceBackground {
          gatsbyImageData
        }
        focusedOnCommunityImage {
          gatsbyImageData
        }
        focusedOnCommunityBackground {
          gatsbyImageData
        }
        differnceDescription {
          paragraphs {
            name
            value
          }
          heading
    }
    subTitle
    proudAsPunch
    proudAsPunchPargraph
    focusedOnCommunityParagraph
    focusedOnCommunityTitle

    timeline {
     
            image {
                gatsbyImageData
            }
            year
            event
    }
    proofPoints:proofPoint {
      name
      image {
        gatsbyImageData
      }
    }
    }
}
`