import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ContentContainer from '../content-container/content-container'


export default function OversetImage({title, image, background}) {
    return (
        <ContentContainer>
            <div className='overset--image'>
                <div className='w100 bg-leather max-1500 ma z-2 re overflow-hidden pos-rel br6'>
                    <div className='bg-wrapper ratio-2-1'>
                        <GatsbyImage alt='' image={background?.gatsbyImageData} className='h100 bg-image' />
                    </div>
                </div>
            </div>
            {( image ?
            <div className='overset--image-container re overset--image--top z-2'>
                <div className='w100 max-1000 ma z-2 re pl30 pr30'>
                    <div className='bg-wrapper ratio-2-1'>
                        <div className='bg-image'>
                            <GatsbyImage alt='' image={image?.gatsbyImageData} className='h100 r6' />
                        </div>

                    </div>
                </div>
            </div>
            :
            ''
            )}

        </ContentContainer>
    )
}
