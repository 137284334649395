import React from 'react';

export default function Excellence() {
  return <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99869 1H3.99956L1 6.09636L6.99913 14L13 6.09636L9.99869 1Z" stroke="#ff6326 " stroke-miterlimit="10" />
    <path d="M1 6L13 6" stroke="#ff6326 " stroke-miterlimit="10" />
    <path d="M4 1L7 5L10 1" stroke="#ff6326 " stroke-miterlimit="10" />
  </svg>
    ;
}



