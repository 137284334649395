import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import SmallSpacer from '../spacer/small-spacer'

export default function ProofPoint({proofPoint}) {
    return (
        <div className=' w100 pl30 pr30 h100'>
            <div className='w100'>
                <div className='pt50 bg-wrapper r6'>
                    <div className='bg-image'>
                       {proofPoint ? <GatsbyImage alt="" className='h100'  image={proofPoint?.image.gatsbyImageData}/> : <StaticImage  alt="" src='../../images/bush.jpeg' className='h100'/>}
                    </div>
                   
                </div>
            </div>
            <SmallSpacer/>
            <h4 className='h5 mb10'>{proofPoint?.name ?proofPoint.name  : 'placeholder content' }</h4>
            <p className='body-copy'>{proofPoint?.description}</p>
            
        </div>
    )
}
